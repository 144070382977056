/* Reset global box-sizing for layout consistency */
* {
  box-sizing: border-box;
}

/* About page container inside SimplePageTemplate */
.simple-page-template .about-page {
  max-width: 100%;
  width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Heading styling */
.simple-page-template .about-page h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #40007d;
}

/* Section styling */
.simple-page-template .about-section {
  padding: 20px;
  margin-bottom: 20px;
}

/* Subheadings */
.simple-page-template .about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #40007d;
}

/* Paragraph styling */
.simple-page-template .about-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

/* Flexbox for intro section */
.simple-page-template .about-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Ensure content wraps on smaller screens */
}

/* Profile picture container */
.simple-page-template .profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add some spacing below the image */
  width: auto; /* Ensures no parent sizing issues */
  height: auto;
}

.simple-page-template .about-intro .profile-picture img {
  max-width: 100px !important; /* Smaller width */
  max-height: 100px !important; /* Equal height to make the image square */
  width: 100%; /* Ensures it scales responsively */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the circle */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for effect */
  display: block;
}


/* Ensure text stays within bounds and is responsive */
.simple-page-template .about-section {
  width: 100%;
  box-sizing: border-box;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .simple-page-template .about-intro {
    flex-direction: column;
    text-align: center;
  }

  .simple-page-template .about-page {
    padding: 10px;
  }
}
