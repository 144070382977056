/* Ensure .about-page stays within the SimplePageTemplate's container */
.simple-page-template .about-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden; /* Ensure no content overflows outside the box */
}

.simple-page-template .about-page h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #40007d;
}

.simple-page-template .about-section {
  padding: 20px 40px;
}

.simple-page-template .about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #40007d;
}

.simple-page-template .about-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
}

.simple-page-template .about-intro {
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

/* Explicit image rules to enforce the sizing and shape */
.profile-picture img {
  width: 150px !important;  /* Set explicit width */
  height: 150px !important; /* Set explicit height */
  border-radius: 50% !important; /* Forces the image to be circular */
  object-fit: cover !important;  /* Ensures the image covers the circular area */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow */
  display: block !important; /* Forces display to block */
  overflow: hidden !important; /* Hides any overflow */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .simple-page-template .about-intro {
    flex-direction: column;
    text-align: center;
  }

  .simple-page-template .about-section {
    padding: 20px;
  }
}
