.admin-dashboard {
    padding: 20px;
  }
  
  .admin-dashboard h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .admin-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-menu li {
    margin: 15px 0;
  }
  
  .admin-menu a {
    color: #007bff;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .admin-menu a:hover {
    text-decoration: underline;
  }
  