/* Subpage header */
.subpage-header {
    background-color: #10011e;
    color: white;
    text-align: center;
    padding: 50px 20px;
  }
  
  .subpage-hero {
      background: url('/images/DataPrivacy.webp') no-repeat center center;
      background-size: 65% auto; /* Change from 'cover' to 'contain' to make the entire image visible */
      background-blend-mode: overlay; /* For blending the image with a color */
      background-color: rgba(255, 255, 255, 0.9); /* Increase the opacity of the white overlay for a more faint effect */
      padding: 30px 10px; /* Reduce padding */
      height: 20vh; /* Set height to half of the viewport height */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: brightness(70%); /* Reduce brightness of the background image */
      opacity: 1.5; /* Further reduce opacity of the entire hero section */
       
  }
  
  
  .subpage-hero-content {
  max-width: 800px;
  padding: 20px; /* Reduce padding */
  background: rgba(255, 255, 255, 0.85); /* Slightly opaque background */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .subpage-hero-content h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .subpage-hero-content p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .subpage-hero .cta-button {
    background-color: #40007d;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .subpage-hero .cta-button:hover {
    background-color: #5e1a9b;
  }
  
  /* Main content */
  .subpage-content {
    padding: 40px 20px;
  }
  
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subpage-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .subpage-content p {
    font-size: 1.1rem;
    line-height: 1.8;
  }
  
  /* Footer */
  .subpage-footer {
    background-color: #10011e;
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 30px;
  }
  