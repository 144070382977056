/* Header Styles */
.header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #10011e; /* Updated background color */
  padding: 10px 20px; /* Reduced padding */
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
  height: auto;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  width: 160px;
  height: auto;
}

.company-name {
  font-family: 'Roboto', sans-serif; /* Modern and tech-savvy font */
  color: #A259FF;  /* Pinkish color */
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 2px;  /* Add some spacing for a more techy look */
  padding-left: 8px;
}

.navbar {
  display: flex;
  background-color: #10011e;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  font-size: 18px;
}

.nav-links a {
  color: white;  /* Keep text color white to contrast with purple background */
  text-decoration: none;
  transition: color 0.3s ease;
  background-color: #10011e;

}

.nav-links a:hover {
  color: #f7b731;  /* Hover color to give a nice accent */
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger span {
  width: 30px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.nav-links.show {
  display: block;
}

.header-subtext {
  text-align: center;
  font-size: 2.5rem; /* Adjust size as needed */
  color: #40007d;    /* Use the same purple shade as the company color */
  margin-top: 20px;  /* Add spacing between the header and the subtext */
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .logo {
    width: 120px;  /* Smaller logo on mobile */
  }
  .header-subtext {
    font-size: 1.2rem;  /* Smaller text size on mobile */
    margin-top: 5px;
  }
}

/* Hero Section */
.hero-section {
  background: url('/images/amsterdam.jpeg') no-repeat center center;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.8);
  height: 25vh; /* Adjusted height to make the section smaller */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.hero-content {
  max-width: 800px;
  padding: 20px 30px; /* Further reduced padding */
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.hero-section h1 {
  font-size: 2rem; /* Reduce font size slightly */
}

.hero-section p {
  font-size: 1.2rem; /* Adjust font size for smaller hero */
  margin-top: 10px;
}

.hero-section .cta-button {
  margin-top: 15px; /* Reduced space above the button */
  padding: 8px 16px; /* Adjusted button padding */
  font-size: 1rem;
}

