.blog-page-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.blog-posts-container {
  width: 70%;
  flex: 2;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for blog posts */
  gap: 20px;
}

.post-card {
  background-color: #f0f0f0; /* Gray background for better contrast */
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  height: auto; /* Remove fixed height */
  display: flex;
  flex-direction: column; /* Ensure vertical stacking */
  justify-content: flex-start;
  color: #333; /* Darker color for contrast */
  text-align: left; /* Align text to the left */
}

.post-content {
  padding: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Light background for content */
  border-radius: 0 0 8px 8px;
}

.post-card h2 {
  font-size: 24px;
  color: #40007d; /* Match title color with website theme */
  margin-bottom: 10px;
}

.post-meta {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.post-excerpt {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

.read-more {
  background-color: #f7b731;
  padding: 8px 12px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.read-more:hover {
  background-color: #ffc94a;
}

.social-media-feed {
  width: 30%;
}

.read-more-link {
  color: #40007d;
  text-decoration: none;
  font-weight: bold;
}

.read-more-link:hover {
  text-decoration: underline;
}

.post-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
