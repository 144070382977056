.case-studies-page {
    padding: 40px;
  }
  
  .case-studies-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .case-study-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33% - 20px);
  }
  
  .case-study-card h2 {
    font-size: 24px;
    color: #40007d;
  }
  
  .case-study-card p {
    font-size: 16px;
    color: #666;
  }
  
  .case-study-card a {
    color: #40007d;
    text-decoration: none;
  }
  