
.header {
  margin-top: 0;
  display: contents;
  font-family: Helvetica Neue, sans-serif;
  background-color: rgb(246, 250, 251);
  height: 119px;
  border-bottom: grey;
  border-bottom-style: solid;
  border-bottom-width: thin;
  background-color: rgb(14,21,41);
}

.topmenu {
  grid-row: 1;
  grid-column: 2;
  list-style: none;
  display: flex;
  align-content: space-between;
  background-color: rgb(14,21,41);
}

.topmenu > li {
  padding: 9px;
  margin-left: 0px;
  margin-top: 5px;
  height: 20px;
  font-size: 19px;
}

.topmenu a {
  padding: 9px;
  margin-left: 0px;
  margin-top: 5px;
  height: 20px;
  font-size: 19px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}
/* this property is a new subset of the li element - question what else it can do?*/
/*.topmenu > li::marker{
  color: #61DAFB;*/

.head--title {
  grid-column: 1;
  grid-row: 1;
  color: #40007d;
  font-family: Helvetica Neue, sans-serif;
  display: flex;
  align-self: left;
  background-color: rgb(255, 255, 255);
}

.head--title h1 {
  font-size: 50px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
}

.head--title h4 {
  font-size: 19px;
  font-weight: 300;
  font-style: italic;
}

.head--logo {
  grid-column: 1;
  grid-row: 1;
  height: 129px;
  width: 129px;
}

.header--menu {
  display: grid;
  grid-template-columns: 520px auto;
  grid-template-rows: 100px;
  height: 70px;
}

.banner {
  display: grid;
  grid-template-columns: 350px 850px 200px;
  grid-template-rows: 300px;
  column-gap: 15%;
  border-bottom-style: groove;
  border-bottom-width: 1px;
  padding-top: 0px;
  margin-top: 0px;
}

.banner--box--right {
  padding-top: 0px;
  margin-top: 3rem;
  height: 110px;
  line-height: 1.2rem;
  color: rgba(3, 0, 8, 0.959);
}

.banner--box--right h4 {
  font-size: 12px;
  margin-left: 1rem;
  font-style: italic;
}

.banner--menu--right--pic {
  height: 2px;
  width: 9px;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 45px;

  /*background-image: url(./components/images/bonlogo.png);
  background-repeat: repeat;*/
}

.body--text {
  display: grid;
  grid-column-gap: 20%;
  grid-row-gap: 155px;
  grid-template-columns: 200px 40% auto;
  grid-template-rows: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 5px;
  letter-spacing: +0.11rem;
  font-weight: 250;
  color: #000000;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: +0.09rem;
  margin-bottom: 4.9em;
}

.body--text--item1 {
  grid-column-start: 1;
  grid-row-start: 1;
  padding: 0.9rem;
}

.body--text--item1 p {
  border-bottom-width: thin;
  font-weight: 400;
  border-bottom: solid;
}

.body--text--item2 {
  grid-column-start: 2;
  grid-row-start: 1;
  display: flexbox;
  padding: 0.7rem;
  margin-top: 5rem;
}

.body--work--boxes {
  display: grid;
  grid-column-gap: 10%;
  grid-template-columns: 150px 60% auto;
  grid-template-rows: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 5px;
  letter-spacing: +0.11rem;
  font-weight: 250;
  color: #000000;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: +0.09rem;
  margin-bottom: 4.9em;
}

.front--blog--card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-rows: 2;
  grid-column-gap: 6em;
  grid-row-gap: 4em;
}

.main--blog--card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 400px;
  grid-column-gap: 1.5em;
  grid-row-gap: 1.5em;
}

.main--blog--card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.work--body--grid {
  margin: 0;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: 200px auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 5px;
  letter-spacing: +0.11rem;
  font-weight: 250;
  color: #000000;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: +0.09rem;
  background-color: rgb(255, 255, 255);
}

.body--text--work--grid {
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: 2;
}

.body--text--work--grid h3 {
  margin-top: 1em;
  text-decoration: none;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.work--single--article h4 {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  font-style: normal;
  font-family: -apple-system, "Helvetica Neue", sans-serif;
}

.work--single--article p {
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  font-style: normal;
  font-family: -apple-system, "Helvetica Neue", sans-serif;
}

.work--preview {
  padding: 10px 16px;
  margin: 0;
  font-size: 18px;
  color: #40007d;
  border-radius: 20px;
  border-width: 0.07em;
  box-shadow: 0.07em 0.07em #40007d;
  border-style: groove;
}

.work--preview:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.work--preview h2 {
  margin: 0px 0px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(83, 10, 227, 0.959);
  font-family: Arial, Helvetica, sans-serif;
}

.work--preview h3 {
  padding: 15px 16px;
  margin: 0px 0px;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 900;
  color: #40007d;
  font-family: Arial, Helvetica, sans-serif;
}

.work--preview h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  font-style: normal;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  line-height: 1.9;
  letter-spacing: +0.05rem;
}

.work--preview h5 {
  font-size: 16px;
  color: #000000;
  text-align: justify;
  text-justify: auto;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 300;
  line-height: 1.9;
  letter-spacing: +0.05rem;
}

.work--preview h6 {
  font-size: 12px;
  color: #000000;
  text-align: justify;
  text-justify: auto;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 300;
  line-height: 1.9;
  letter-spacing: +0.05rem;
}

.work--preview p {
  font-size: 14px;
  color: #000000;
  text-align: justify;
  text-justify: auto;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 300;
  line-height: 1.9;
  letter-spacing: +0.07rem;
}

.work--preview a:link {
  text-decoration: none;
  color: #000000;
}

.work--preview a:visited {
  text-decoration: underline;
  background-color: #174b17;
}

.work--preview a:hover {
  background-color: #c9d1cb;
}

.sidemenu {
  padding: 9px;
  grid-column-start: 1;
  display: flexbox;
  margin-left: 0px;
  margin-top: 5px;
  margin-right: 0;
  padding-right: 5px;
  color: rgba(83, 10, 227, 0.959);
  background-color: rgb(246, 250, 251);
  height: fit-content;
  align-content: space-between;
  list-style: none;
  width: fit-content;
  line-height: 35px;
  font-weight: 500;
  list-style-type: square;
}

.sidemenloc {
  padding: 9px;
  grid-column-start: 1;
  display: flexbox;
  margin-left: 0px;
  margin-top: 5px;
  margin-right: 0;
  padding-right: 5px;
  color: rgb(235, 91, 38);
  background-color: rgb(246, 250, 251);
  height: fit-content;
  align-content: space-between;
  list-style: none;
  width: fit-content;
  font-weight: 400;
  line-height: 35px;
}

.body--text--item3 {
  grid-column-start: 3;
  background-color: rgb(255, 255, 255);
  display: flexbox;
}

.body--text--item3 > h3 {
  margin-top: 45px;
  margin-left: 30px;
  font-weight: 400;
  text-decoration: solid;
  font-size: 20px;
}

.body--text--item3 > p {
  font-weight: 400;
}

nav {
  display: flex;
  background-color: #869188;
}

.content--articles {
  color: #003300;
  font-family: Inter, sans-serif;
}

.content--artitem {
  color: #003300;
  font-family: Inter, sans-serif;
}

.fotosticker {
  color: yellow;
  position: absolute;
  background-color: red;
  font-weight: bold;
}

.art-tile {
  position: relative;
  width: 185px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.fotito {
  height: 249px;
  width: 249px;
  background-color: blue;
}

.footer--main {
  color: #1e2b1e;
  border-top: solid;
  border-top-style: groove;
}

.footer--main > h5 {
  font-size: 9px;
}

/* new styles for testing*/

.clienticon {
  color: red;
}

.try--home {
  color: red;
}

.linkedin--share--blog {
  margin-top: 14px;
}

.single--page {
  padding: 10px 16px;
  margin-top: 2rem;
  font-size: 18px;
  color: rgba(3, 0, 8, 0.959);
}

.single--page p {
  white-space: pre-line;
  font-weight: 400;
  font-size: 22px;
  margin-left: 3rem;
  text-align: justify;
  text-justify: inter-cluster;
}

.single--page h3 {
  color: rgba(83, 10, 227, 0.959);
}

.single--page--work {
  grid-column-start: 2;
  grid-row-start: 2;
  background-color: rgb(255, 255, 255);
  display: flexbox;
}

.foto--row {
  display: grid;
  grid-template-columns: 25% 50% 30%;
  grid-template-rows: auto;
}

.foto {
  grid-column-start: 2;
  grid-row-start: 1;
  height: 9vmin;
  pointer-events: none;
  align-items: center;
  border-radius: 2em;
  border-style: groove;
  align-self: center;
  justify-self: center;
  margin-top: 2rem;
}

.slogan {
  grid-column-start: 2;
  margin-top: 5rem;
}

.slogan h1 {
  font-size: 4rem;
  font-family: 'Times New Roman', Times, serif ; 
  color: rgba(83, 10, 227, 0.959);
}

.slogan h3 {
  font-size: 2rem;
  color: rgba(83, 10, 227, 0.959);
}

.cookie--bar--container {
  height: 200px;
  position: "fixed";
  left: 0;
  width: "100%";
  height: "100%";
}

.cookie--policy {
  margin-top: 1.7rem;
  font-size: 1.4rem;
}

.cookie--policy--link {
  color: #13f2dd;
  font-size: 1.2rem;
}

.cookie--page {
  display: grid;
  grid-column-gap: 10%;
  grid-row-gap: 10px;
  grid-template-columns: 200px 70% auto;
  grid-template-rows: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  padding: 5px;
  letter-spacing: +0.11rem;
  font-weight: 250;
  color: #000000;
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: +0.09rem;
  margin-bottom: 4.9em;
}

.cookie--page h1 {
  color: #40007d;
  font-size: 28px;
}

.cookie--table {
  grid-column-start: 2;
  grid-row-start: 2;
  display: flexbox;
  padding: 0.7rem;
  margin-top: 1rem;
}

.cookie--table table {
  border: 1px solid;
}

.cookie--table td,
th {
  border: 1px solid;
  padding: 8px;
  background-color: rgb(228, 222, 222);
}

/* Footer styles */
.footer--main {
  background-color: #333; /* Adjust to fit your footer background */
  color: #fff;
  text-align: center;
  padding: 20px;
  position: relative;
}

/* Wrapper for the Auth buttons to prevent full-width button issue */
.auth-button-wrapper {
  display: inline-block;
  margin-top: 10px;
}

/* Styling for Auth buttons in the footer */
.auth-button-wrapper button {
  background-color: #f7b731;
  color: #000;
  font-size: 0.9rem;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button-wrapper button:hover {
  background-color: #f5a623;
}

/* Make sure the button does not expand to full width */
.auth-button-wrapper button {
  display: inline-block;
  width: auto;
}
