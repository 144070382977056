.social-share {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .social-share h3 {
    margin-right: 20px;
    font-size: 18px;
  }
  
  .share-btn {
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .share-btn.twitter {
    background-color: #1DA1F2;
  }
  
  .share-btn.twitter:hover {
    background-color: #0d8ae5;
  }
  
  .share-btn.facebook {
    background-color: #4267B2;
  }
  
  .share-btn.facebook:hover {
    background-color: #365899;
  }
  
  .share-btn.linkedin {
    background-color: #0077B5;
  }
  
  .share-btn.linkedin:hover {
    background-color: #005e93;
  }
  