/* Typography */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.7;
  color: #333;
}

h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
  color: #40007d;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 15px;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
}

p, li {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Link styles */
a {
  color: #40007d;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

a:hover {
  color: #f7b731;
}

/* Button styles */
button {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background-color: #40007d;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #f7b731;
}

/* CTA Button Styling - Softer Look */
.cta-button {
  background-color: #40007d;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #5e1a9b;
  transform: none;
}

/* Hero Section */

.hero-section {
  background: url('/images/backgroundpatch.webp') no-repeat center center;
  background-size: cover;
  background-blend-mode: overlay; /* For blending the image with a color */
  background-color: rgba(255, 255, 255, 0.8); /* Add a white overlay for faint effect */
  padding: 40px 20px;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44vh; /* Increase the height of the hero section */
}

.hero-content {
  max-width: 800px;
  padding: 20px; /* Reduced padding */
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.hero-section .cta-button {
  margin-top: 15px; /* Reduced margin to bring the button closer to the content */
}

.hero-section p {
  font-size: 1.25rem; /* Slightly smaller font size for the second line */
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin-top: 15px; /* Reduced margin to decrease the gap */
}



/* Main content wrapper */
.content-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 40px;
}

/* Main content area */
.main-content-box {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 20px;
}

.main-content {
  width: 80%;
  max-width: 1000px;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  font-size: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.main-content p {
  font-size: 1.2rem; /* Increase font size for the single page text */
  line-height: 1.8;
}


/* Areas of Practice Section - Full Width */
.areas-of-practice {
  width: 100%;
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.areas-of-practice h3 {
  margin-bottom: 20px;
}

.practice-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Practice card styling */
.practice-card {
  width: calc(25% - 40px); /* Four cards per row */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.practice-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.practice-card .card-icon {
  font-size: 2rem;
  color: #40007d;
  margin-bottom: 10px;
}

.practice-card h4 {
  font-size: 1.4rem;
  color: #40007d;
  margin-bottom: 10px;
}

.practice-card p {
  font-size: 1rem;
  color: #666;
}

/* Recent Work Section */
.recent-work-section {
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.recent-work-section h2 {
  margin-bottom: 20px;
}

/* Flex container for Recent Work and LinkedIn feed */

/* Container for recent work and widget */

.recent-work-container {
  display: flex;
  flex-wrap: nowrap;  /* Ensure that items stay on the same row */
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  gap: 20px;  /* Space between the recent work and the right column */
  width: 100%;
  margin-bottom: 20px;
}

/* Recent work section */
.recent-work-section {
  flex: 2;  /* Take up 2/3 of the width */
  max-width: 65%;
  box-sizing: border-box;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Right column for LinkedIn feed */
.right-column {
  flex: 1;  /* Take up 1/3 of the width */
  max-width: 30%;
  box-sizing: border-box;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ensure the widget takes full width */
.elfsight-app-02ebe45b-78fb-49c9-98c8-f172cd18ef80 {
  width: 100%;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .main-content,
  .areas-of-practice {
    width: 100%;
  }

  .practice-card {
    width: 100%; /* Full width on smaller screens */
  }
}

.areas-of-practice {
  width: 100%;
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.practice-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.practice-card {
  width: calc(25% - 40px); /* Maintain four cards per row */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.practice-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.practice-card .card-icon {
  font-size: 2rem;
  color: #40007d;
  margin-bottom: 10px;
}

.practice-card h4 {
  font-size: 1.4rem;
  color: #40007d;
  margin-bottom: 10px;
}

.practice-card p {
  font-size: 1rem;
  color: #666;
}

/* Responsive design */
@media (max-width: 768px) {
  .recent-work-container {
    flex-direction: column;  /* Stack items vertically on smaller screens */
  }

  .recent-work-section,
  .right-column {
    max-width: 100%;
    width: 100%;
  }
}
